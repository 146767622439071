import React from 'react';
import imageUrl from "../assets/images/services/electronics2_sm.jpg";

const AboutUs: React.FC = () => {

    return (
        <section className="py-20">
            <div className="container mx-auto px-6 flex flex-col lg:flex-row items-center max-w-8xl lg:gap-x-12">
                {/* Left Image Section (Using background-image) */}
                <div 
                    className="lg:w-1/3 w-full h-96 bg-cover bg-center rounded-lg shadow-lg"
                    style={{ backgroundImage: `url(${imageUrl})` }} // Dynamic background image
                ></div>

                {/* Right Text Section */}
                <div className="lg:w-2/3 w-full text-center lg:text-left mt-6 lg:mt-0">
                    <p className="text-xl md:text-2xl lg:text-3xl font-light leading-loose mb-6">
                        At Exine Technology, we combine our engineering expertise with cutting-edge technology to deliver traceability systems tailored to your business needs.
                    </p>
                    <p className="text-xl md:text-2xl lg:text-3xl font-light leading-loose">
                        We pride ourselves on understanding your unique processes and delivering solutions that improve efficiency and accuracy.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
