import heroImage from "../assets/images/hero2.png";
import { useState } from "react";

import { Link } from "react-router-dom";
import AssetStats from "./AssetStats";
const Hero: React.FC = () => {
  const [overlayOpacity] = useState(75); // Default 50%

  return (
    <section className="relative h-[75vh] md:h-screen flex items-center">
      {/* Background wrapper with overlay */}
      <div className="absolute inset-0 w-full h-full">
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${heroImage})`,
          }}
        />
        {/* Overlay only on the image */}
        <div
          className="absolute inset-0 bg-black"
          style={{ opacity: overlayOpacity / 100 }}
        />
      </div>

      {/* Content remains separate and above overlay */}
      <div className="container mx-auto flex flex-col-reverse md:flex-row items-center justify-between px-6 relative z-10">
        <div className="flex flex-col justify-center items-start text-center md:text-left md:w-1/2">
          <h1 className="text-5xl md:text-6xl font-bold leading-tight mb-4 text-white">
            Fast track to smarter productivity
          </h1>
          <p className="text-xl md:text-2xl mb-6 text-pink">
            Struggling with observability, conformance or productivity? Don't
            worry, Exine has got you covered
          </p>
          <div className="container mx-auto px-6 text-center max-w-5xl mb-6"> {/* Increased max width */}
            <div className="flex justify-center items-center">
            <AssetStats />
            </div>
        </div>
          {/* Opacity Control (Optional) */}
          {/* <div className="mt-4">
            <label className="text-white">Overlay Opacity: {overlayOpacity}%</label>
            <input
              type="range"
              min="0"
              max="100"
              value={overlayOpacity}
              onChange={(e) => setOverlayOpacity(Number(e.target.value))}
              className="ml-2"
            />
          </div> */}
          <div className="container mx-auto px-6 text-center max-w-8xl py-6">
            {" "}
            {/* Increased max width */}
            <div className="flex justify-center items-center">
              <Link
                to="/case-studies"
                className="bg-primary hover:bg-primary-dark text-white font-bold py-5 px-6 rounded-full"
              >
                Learn More About Our Work
              </Link>
            </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;


    /*
    ✅ h-[75vh] ensures a large hero without forcing full height.
✅ md:h-screen makes it fullscreen on larger screens.
✅ flex items-center centers content nicely.
*/