import React from 'react';
import NumberSpinner from './NumberSpinner';

const AssetStats: React.FC = () => {
    return (
      
        <section>
            <div className="container mx-auto px-6 text-center max-w-8xl text-white"> {/* Increased max width */}
                <div className="flex justify-center items-center">
                    <div>
                        <h2 className="text-l md:text-xl lg:text-2xl  font-light leading-loose rounded">Assets Tracked:</h2>
                        <div className="text-xl md:text-2xl lg:text-3xl leading-loose rounded font-bold">
                         <NumberSpinner totalNumber={2218765} timespan={1.5} startNumber={0} /> {/* Example of 10,000 tracked assets over 10 seconds */}
                        </div>
                    </div>
                    <div className="ml-24 ">
                        <h2 className="text-l md:text-xl lg:text-2xl font-light leading-loose rounded" >Events Recorded:</h2>
                        <div className="text-xl md:text-2xl lg:text-3xl leading-loose rounded font-bold">
                            <NumberSpinner totalNumber={23735926} timespan={2.2} startNumber={0} /> {/* Example of 10,000 tracked assets over 10 seconds */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AssetStats;
