// src/pages/Home.tsx
import React from "react";
import Hero from "../components/Hero";
import Testimonials from "components/Testimonials";
import Partners from "components/Partners";
import Services from "components/Services";
import AboutUs from "components/AboutUs";
import ContactUs from "components/ContactUs";
import HowWeHelp from "components/HowWeHelp";

const Home: React.FC = () => {
  return (
    <div className="font-sans">
      <Hero />

      <div className="bg-primary text-pink">
        <HowWeHelp />
      </div>

      <div className="bg-custom-background rounded-3xl mx-4 lg:mx-8 my-12 overflow-hidden">
        <AboutUs />
      </div>

      <div className="bg-primary text-pink">
        <Services />
      </div>
      {/* <div className="bg-custom-background">
                <Experience />
            </div> */}
     <div className="bg-custom-background rounded-3xl mx-4 lg:mx-8 my-12 overflow-hidden">
        <Testimonials />
      </div>
      <div className="bg-primary text-pink">
        <Partners />
      </div>
      <div className="bg-custom-background rounded-3xl mx-4 lg:mx-8 my-12 overflow-hidden">
        <ContactUs />
      </div>
    </div>
  );
};

export default Home;
