import React from "react";
import electronicsImg from "../assets/images/services/electronics2_sm.jpg";
import webDevImg from "../assets/images/services/web-dev_sm.jpg";
import uxImg from "../assets/images/services/ux_sm.jpg";

interface Service {
  title: string;
  description: string;
  image: string;
}

const services: Service[] = [
  {
    title: "Bespoke Solutions",
    description:
      "No need to invest in off-the-shelf applications that make you adapt to the package. Our bespoke traceability systems boost operational efficiency, reduce manual errors, and provide clear, actionable insights.",
    image: webDevImg,
  },
  {
    title: "System Development",
    description:
      "Responsive, scalable, and secure web applications tailored to your business needs. More than pure traceability, a platform where your clients can access and interact  with your services.",
    image: electronicsImg,
  },
  {
    title: "Electronics Design",
    description:
      "We design and manufacture custom electronics, including the development of embedded software. These seamlessly link your physical assets and processes to our software.",
    image: uxImg,
  },
  {
    title: "Continued Support",
    description:
      "Our systems are built for growth. And you can be sure we'll be around to support you as your business grows and  your needs change.",
    image: uxImg,
  }
];

const Services: React.FC = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-4xl font-bold mb-12 text-white">
          What we do
        </h2>
        <p className="text-xl md:text-xl lg:text-xl text-pink font-light leading-loose max-w-8xl mx-auto">
          Our traceability solutions give SMEs the power to monitor and control
          the movement of assets through real processes. Capturing this core
          data allows us to provide higher level business support.
        </p>
        <br />
       {/* Grid Layout for 2 Columns */}
       <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-3">
          {services.map((service, index) => (
            <div
              key={index}
              className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden p-6"
            >
              <div className="flex-shrink-0">
                {/* <img className="w-full h-48 object-cover" src={service.image} alt={service.title} /> */}
              </div>
              <div className="flex flex-col flex-grow justify-start">
                <h3 className="text-xl font-bold mb-4 text-primary">
                  {service.title}
                </h3>
                <p className="text-gray-700">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;

// <div className="flex flex-wrap justify-center items-stretch py-3">
//           {services.map((service, index) => (
//             <div
//               key={index}
//               className="flex flex-col max-w-sm w-full bg-white rounded-lg shadow-lg overflow-hidden mx-4 mb-6"
//             >
//               <div className="flex-shrink-0">
//                 {/* <img
//                   className="w-full h-48 object-cover"
//                   src={service.image}
//                   alt={service.title}
//                 /> */}
//               </div>
//               <div className="flex flex-col flex-grow p-6 justify-start">
//                 {" "}
//                 {/* Align items at the top */}
//                 <h3 className="text-xl font-bold mb-4 text-primary">
//                   {service.title}
//                 </h3>
//                 <p className="text-gray-700">{service.description}</p>
//               </div>
//             </div>
//           ))}
//         </div>