// src/pages/About.tsx
import React from 'react';

const About: React.FC = () => {
  return (
    <div className="font-sans bg-offWhite">
      <section className="container mx-auto py-20 px-6">
        <h1 className="text-4xl font-bold text-center text-primary-dark mb-12">Our Story</h1>
        <p className="text-lg text-gray-700 leading-relaxed mb-12">
          Founded by two PhD graduates from Loughborough University, Exine Technology was born out of a passion for industrial innovation. With backgrounds in mechanical engineering and product design, Richard and Aaron saw the potential for better traceability systems in the manufacturing domain. Today, they leverage their coding skills and engineering knowledge to provide bespoke solutions that help SMEs gain control over their operations.
        </p>
        <p className="text-lg text-gray-700 leading-relaxed mb-12">
          Exine Technology has grown into a company that prides itself on understanding the unique processes of each client. Our traceability systems are not just tools—they are custom solutions designed to fit your business like a glove, helping you monitor, manage, and optimise your operations with precision.
        </p>
      </section>

      <section className="bg-primary py-20 px-6 text-pink">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Our Mission</h2>
          <p className="text-lg  leading-relaxed text-center text-white">
            At Exine Technology, our mission is to empower small and medium-sized businesses with innovative traceability solutions that enhance operational efficiency, reduce manual errors, and provide actionable insights. We believe in building long-lasting relationships with our clients by truly understanding their needs and delivering solutions that exceed expectations.
          </p>
        </div>
      </section>

      <section className="container mx-auto py-20 px-6">
        <h2 className="text-3xl font-bold text-center text-primary-dark mb-12">Meet the Founders</h2>
        <div className="flex flex-wrap justify-center">
          <div className="w-full md:w-1/2 lg:w-1/3 p-4 text-center">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-bold mb-2">Richard</h3>
              <p className="text-primary-dark mb-4">Co-Founder</p>
              <p className="text-gray-700">
                Richard’s background in mechanical engineering and passion for industrial systems led him to co-found Exine Technology. He brings deep technical expertise and a commitment to innovation in every project.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 p-4 text-center">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-bold mb-2">Aaron</h3>
              <p className="text-primary-dark mb-4">Co-Founder</p>
              <p className="text-gray-700">
                Aaron’s expertise in electronics design and his passion for 3D printing are key to Exine Technology’s bespoke solutions. Aaron’s innovative thinking ensures that every design is not only functional but also tailored to meet our clients’ needs.
              </p>
            </div>
          </div>
          {/* <div className="w-full md:w-1/2 lg:w-1/3 p-4 text-center">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-bold mb-2">Heather</h3>
              <p className="text-primary-dark mb-4">Full-Stack Developer</p>
              <p className="text-gray-700">
                Our skilled full-stack developer plays a crucial role in bringing our innovative ideas to life, ensuring that our traceability systems are robust, scalable, and tailored to meet the specific needs of our clients.
              </p>
            </div>
          </div> */}
        </div>
      </section>

      <section className="bg-primary-light py-20 px-6 text-pink">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Why We're Different</h2>
          <p className="text-lg leading-relaxed text-center text-white">
            We don't just build systems—we build relationships. At Exine Technology, we take the time to get to know your business, your processes, and your goals. Our bespoke solutions are designed to fit your unique needs, and our commitment to ongoing support ensures that your systems grow and adapt with your business.
          </p>
        </div>
      </section>
    </div>
  );
};

export default About;
