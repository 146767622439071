import React from 'react';
import { Timer, BadgePoundSterling } from "lucide-react"; // Importing icon
const HowWeHelp: React.FC = () => {
    return (
        <section className="py-20 flex items-center">
        <div className="container mx-auto px-6 text-center max-w-8xl">
            <div className='pb-10'>
            <h3 className="text-4xl font-bold mb-6 text-white">
                Take Control
            </h3>
            </div>
            <p className="text-pink text-lg md:text-xl font-light leading-relaxed mb-10">
                At Exine, we're born problem solvers. There's nothing we love more than helping a business beat challenges and spark new growth.
            </p>
             {/* Bullet Point Sections */}
             <div className="flex flex-col md:flex-row justify-center items-stretch gap-8 mb-12">
                    {/* Left Box */}
                    <div className="w-full md:w-1/2 bg-white  text-white rounded-lg p-10 text-left shadow-lg">
                    {/* Icon at the top */}
                    <Timer className="w-12 h-12 text-pink mb-4" />
                        <ul className="text-xl md:text-2xl font-semibold leading-loose space-y-6 text-black">
                            <li>• Unlock your team's full potential</li>
                            <li>• Boost real-time information</li>
                            <li>• Gain clear insights to make informed decisions</li>
                        </ul>
                    </div>

                    {/* Right Box */}
                    <div className="w-full md:w-1/2 bg-white  text-white rounded-lg p-10 text-left shadow-lg">
                    {/* Icon at the top */}
                    <BadgePoundSterling className="w-12 h-12 text-pink mb-4" />
                        <ul className="text-xl md:text-2xl font-semibold leading-loose space-y-6 text-black">
                            <li>• Tighten up process conformance</li>
                            <li>• Reduce wasted time</li>
                            <li>• Offer unique services within your industry</li>
                        </ul>
                    </div>
                </div>

            <p className="text-pink text-lg md:text-xl font-light leading-relaxed">
                Whatever it takes to help you achieve next-level productivity, we'll get you there.
            </p>
        </div>
    </section>
    
    );
};
export default HowWeHelp;

// <section className="py-20 flex items-center">
// <div className="container mx-auto px-6 text-center max-w-8xl">
//     <div className='pb-10'>
//     <h3 className="text-4xl font-bold mb-6">
//         Take Control
//     </h3>
//     </div>
//     <p className="text-white text-lg md:text-xl font-light leading-relaxed mb-10">
//         At Exine, we're born problem solvers. There's nothing we love more than helping a business beat challenges and spark new growth.
//     </p>
    
//     <ul className="text-left text-white text-lg md:text-xl font-light leading-loose mb-10 grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-8">
//         <li>• Unlock your team's full potential</li>
//         <li>• Tighten up process conformance</li>
//         <li>• Boost real-time information</li>
//         <li>• Reduce wasted time</li>
//         <li>• Gain clear insights to make informed decisions</li>
//         <li>• Offer unique services within your industry</li>
//     </ul>
//     <p className="text-white text-lg md:text-xl font-light leading-relaxed">
//         Whatever it takes to help you achieve next-level productivity, we'll get you there.
//     </p>
// </div>
// </section>